import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Table } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import LineItemForm from './LineItemForm';
import removeRefData from 'helpers/removeRefData';
import { showToast } from 'module/Common/Toast/toast';
import AdditionalInvoiceLineItem from './AdditionalInvoiceLineItem';
import { RentalContractAdditionalInvoiceFormData } from 'helpers/formKeys';

function AdditionalInvoiceTable({ data, setFormData }) {
  const Translate = useAxisproTranslate();
  const [itemFromData, setItemFromData] = useState(
    RentalContractAdditionalInvoiceFormData
  );
  const [editIndex, setEditIndex] = useState(undefined);

  const handleDeleteLineItem = index => {
    if (index > -1 && index < data.length) {
      data.splice(index, 1);
    }
    setFormData(prev => ({
      ...prev,
      details: data
    }));
  };
  const handleFieldChange = (e, action) => {
    if (action?.name === 'item' && action?.action === 'select-option') {
      setItemFromData(prev => ({
        ...prev,
        stock_id: e?.item?.stock_id,
        item_ref: e,
        item: e?.value,
        item_display_name: e?.item?.item_name,
        id: e?.item?.id,
        is_kit: '0',
        quantity: '1',
        line_total: parseFloat(itemFromData?.unit_price) * parseFloat(1)
      }));
    } else {
      let lineTotal = 0;
      const value = e?.target?.value;
      if (e?.target?.name === 'quantity') {
        lineTotal = parseFloat(value) * parseFloat(itemFromData?.unit_price);
      }

      if (e?.target?.name === 'unit_price') {
        lineTotal = parseFloat(value) * parseFloat(itemFromData?.quantity);
      }

      setItemFromData(prev => ({
        ...prev,
        [e?.target?.name]: value,
        line_total: !isNaN(lineTotal) ? lineTotal : '-'
      }));
    }
  };
  const handleEdit = index => {
    setFormData(prev => {
      const updatedDetails = [...prev.details];
      updatedDetails[index] = { ...itemFromData, is_kit: '0' };
      return {
        ...prev,
        details: updatedDetails
      };
    });
    setEditIndex(undefined);
    setItemFromData({});
  };
  const handleLineItemEdit = index => {
    setEditIndex(index);
  };
  const handleCloseEditItem = () => {
    setItemFromData(RentalContractAdditionalInvoiceFormData);
    setEditIndex(undefined);
  };
  const handleAddItem = async () => {
    const newItemFormData = removeRefData(itemFromData);

    if (newItemFormData.unit_price && newItemFormData.stock_id) {
      setFormData(prev => ({
        ...prev,
        details: [...prev.details, newItemFormData]
      }));
      setItemFromData(RentalContractAdditionalInvoiceFormData);
    } else {
      showToast(Translate('Please fill in all required fields'), 'error');
    }
  };
  useEffect(() => {
    if (editIndex === 0 || editIndex) {
      let EditData = data[editIndex];
      setItemFromData(() => ({
        item_ref: {
          label: EditData?.item_display_name,
          value: EditData?.id
        },
        item_display_name: EditData?.item_display_name,
        item: EditData?.id,
        id: EditData?.id,
        stock_id: EditData?.stock_id,
        unit_price: EditData?.unit_price,
        quantity: EditData?.quantity,
        description: EditData?.description,
        line_total: EditData?.line_total
      }));
    }
  }, [editIndex, data]);

  return (
    <div className="mb-0">
      <Table
        striped
        bordered
        responsive
        className="form-table-layout mb-1 simple-table-animation"
      >
        <thead className="text-white bg-dark ">
          <tr>
            <th
              className="border-end p-1 ps-2 text-center"
              style={{ width: '120px' }}
            >
              {Translate('STOCK ID')}
            </th>
            <th className="border-end p-1 ps-2" style={{ width: '300px' }}>
              {Translate('ITEM NAME')}
            </th>
            <th
              className="border-end p-1 ps-2 text-center"
              style={{ width: '130px' }}
            >
              {Translate('QUANTITY')}
            </th>
            <th
              className="border-end p-1 ps-2 text-center"
              style={{ width: '130px' }}
            >
              {Translate('UNIT PRICE')}
            </th>
            <th
              className="border-end p-1 ps-2 text-center"
              style={{ width: '130px' }}
            >
              {Translate('TOTAL')}
            </th>
            <th
              className="border-end p-1 ps-2 text-center"
              style={{ width: '100px' }}
            >
              {Translate('ACTION')}
            </th>
          </tr>
        </thead>
        <tbody>
          {!data.length && (
            <tr>
              <td colSpan={6}>
                <Alert
                  color="warning"
                  className="m-0 text-center fs--2 p-2 rounded-0"
                >
                  {Translate(
                    'Please select an item before proceeding. Make sure all required fields are filled out to avoid any issues'
                  )}
                </Alert>
              </td>
            </tr>
          )}
          {data?.length > 0 &&
            data?.map((items, index) => {
              if (editIndex === index) {
                return (
                  <LineItemForm
                    edit
                    key={index}
                    handleEditItem={() => handleEdit(index)}
                    itemFromData={itemFromData}
                    handleAddLineItem={handleAddItem}
                    handleFieldChange={handleFieldChange}
                    handleCloseEdit={handleCloseEditItem}
                  />
                );
              } else {
                return (
                  <AdditionalInvoiceLineItem
                    items={items}
                    handleDeleteLineItem={() => handleDeleteLineItem(index)}
                    key={index}
                    handleEdit={() => handleLineItemEdit(index)}
                  />
                );
              }
            })}
          {editIndex >= 0 ? (
            ''
          ) : (
            <LineItemForm
              itemFromData={itemFromData}
              handleAddLineItem={handleAddItem}
              handleFieldChange={handleFieldChange}
            />
          )}
        </tbody>
      </Table>
    </div>
  );
}

AdditionalInvoiceTable.propTypes = {
  data: PropTypes.array,
  setFormData: PropTypes.func
};

export default AdditionalInvoiceTable;
