import generateFormDataHelper from 'helpers/generateFormDataHelper';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import axios from 'axios';
import { FaRegMinusSquare } from 'react-icons/fa';
import isObject from 'helpers/isObject';
import { showToast } from 'module/Common/Toast/toast';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import { RiFileList3Fill } from 'react-icons/ri';
import { MdOutlineAddBox } from 'react-icons/md';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

export default function WorkOrderBomModal({
  onHide,
  stockId,
  workOrderId,
  workOrderDetailId
}) {
  const Translate = useAxisproTranslate();
  const formKeys = {
    trans_date: '',
    details: [],
    memo: '',
    work_order_id: workOrderId,
    work_order_detail_id: workOrderDetailId
  };

  const [formData, setFormData] = useState(formKeys);
  const [formError, setFormError] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [bomData, setBomData] = useState([]);
  const [loading, setLoading] = useState(false);
  const checkBoxStyle = { width: '14px', height: '14px' };
  const bomDataLength = bomData?.length;

  const handleSubmit = async event => {
    event.preventDefault();
    setFormError({});

    if (!formData?.details?.length) {
      return showToast('Please add item(s) to the list.', 'error');
    }

    setIsSaving(true);

    try {
      const { data } = await axios.post('purchase/material-request', formData);

      if (data.success) {
        showToast(data.message, 'success');
        onHide();
      } else {
        showToast(data.message || 'Submission failed.', 'error');
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        'Something went wrong, please refresh the page and try again.';
      showToast(errorMessage, 'error');
    } finally {
      setIsSaving(false);
    }
  };

  const getBillOfMaterialsData = useCallback(async () => {
    if (!stockId) return;
    try {
      setLoading(true);
      const { data } = await axios.get(
        `inventory/item-wise-bill-of-materials/${stockId}`
      );
      if (data?.success && Array.isArray(data?.data)) {
        setBomData(data?.data?.filter(item => item?.product_type === 'goods'));
      }
    } catch (error) {
      console.error('Error fetching Bill of Materials:', error);
    } finally {
      setLoading(false);
    }
  }, [stockId]);

  useEffect(() => {
    getBillOfMaterialsData();

    return () => {
      setBomData([]);
      setFormError({});
    };
  }, [stockId, getBillOfMaterialsData]);

  const handleAddOrRemoveToMaterialRequestes = data => {
    if (!data || !isObject(data)) return;

    setFormData(prev => {
      const isDuplicate = prev?.details?.some(item => item.id === data?.id);

      if (isDuplicate) {
        return {
          ...prev,
          details: prev?.details?.filter(item => item.id !== data?.id)
        };
      }

      return {
        ...prev,
        details: [
          ...(prev?.details || []),
          {
            stock_id: data?.stock_id,
            id: data?.id,
            item_display_name: data?.item_name,
            quantity: data?.quantity,
            unit_price: data?.unit_price
          }
        ]
      };
    });
  };

  const handleFieldChange = (e, action) => {
    let newFormData = generateFormDataHelper(e, action, formData);
    setFormData(newFormData);
  };

  const handleCheckAll = e => {
    if (e.target.checked) {
      setFormData(prev => ({
        ...prev,
        details: bomData?.map(item => ({
          stock_id: item?.stock_id,
          id: item?.id,
          item_display_name: item?.item_name,
          quantity: item?.quantity,
          unit_price: item?.unit_price
        }))
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        details: []
      }));
    }
  };

  return (
    <Modal size="xl" show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="text-uppercase d-flex flex-row align-items-center gap-1 fw-bold">
          <RiFileList3Fill size={18} className="text-success" />
          {Translate('Bill of Materials (BOM)')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeof formError === 'object' && Object.keys(formError).length > 0 && (
          <ErrorAlert formError={formError} setFormError={setFormError} />
        )}

        <Form onSubmit={handleSubmit}>
          <Form.Group
            className="mb-4 d-flex flex-wrap gap-4 ms-1"
            controlId="form.date"
          >
            <Form.Label className="require-data">
              {Translate('Date')}
            </Form.Label>
            <div
              className="flex-fill d-flex flex-row align-items-center"
              style={{
                maxWidth: 300
              }}
            >
              <div className="flex-fill">
                <AppDatePicker
                  name="trans_date"
                  value={formData.trans_date}
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  onChange={handleFieldChange}
                  showDefaultDate
                />
              </div>
            </div>
          </Form.Group>

          <Table
            striped
            bordered
            hover
            responsive
            className={`simple-table-animation ${
              loading && 'custom-table-cell-loader'
            }`}
          >
            {!loading && (
              <>
                <thead className="bg-secondary text-light text-uppercase">
                  <tr>
                    <th className="fs--2 p-2 text-center">
                      <div className="d-flex align-items-center justify-content-center gap-2">
                        {bomDataLength > 0 && (
                          <Form.Check.Input
                            type="checkbox"
                            className="m-0"
                            title="Select All"
                            onChange={handleCheckAll}
                            checked={
                              formData?.details?.length === bomDataLength
                            }
                            style={checkBoxStyle}
                          />
                        )}
                        <span>SL</span>
                      </div>
                    </th>
                    <th className="fs--2 p-2 text-center">ITEM CODE</th>
                    <th className="fs--2 p-2 text-center">ITEM Name</th>
                    <th className="fs--2 p-2 text-center">QUANTITY</th>
                    <th className="fs--2 p-2 text-center">UNIT PRICE</th>
                    <th className="fs--2 p-2 text-center">TOTAL AMOUNT</th>
                    <th className="fs--2 p-2 text-center">ACTION</th>
                  </tr>
                </thead>
                <tbody className="text-dark">
                  {bomDataLength > 0 ? (
                    <>
                      {bomData?.map((item, index) => (
                        <tr key={index}>
                          <td className="p-2 text-center">
                            <div className="d-flex align-items-center justify-content-center gap-2">
                              <Form.Check.Input
                                type="checkbox"
                                className="m-0"
                                checked={formData?.details?.find(
                                  data => data?.id === item?.id
                                )}
                                onChange={() =>
                                  handleAddOrRemoveToMaterialRequestes(item)
                                }
                                style={checkBoxStyle}
                              />
                              <span className="pt-1">{index + 1}</span>
                            </div>
                          </td>
                          <td className="p-2 text-center">{item?.stock_id}</td>
                          <td className="p-2 text-center">{item?.item_name}</td>
                          <td className="p-2 text-center">{item?.quantity}</td>
                          <td className="p-2 text-center">
                            {item?.unit_price}
                          </td>
                          <td className="p-2 text-center">
                            {parseFloat(item?.quantity) *
                              parseFloat(item?.unit_price)}
                          </td>
                          <td className="p-2 text-center">
                            {formData?.details?.find(
                              data => data?.id === item?.id
                            ) ? (
                              <Button
                                variant="transparent"
                                size="sm"
                                className="p-0 shadow-none"
                                title="Remove from material requestes"
                                onClick={() =>
                                  handleAddOrRemoveToMaterialRequestes(item)
                                }
                              >
                                <FaRegMinusSquare
                                  className="text-danger"
                                  size={20}
                                />
                              </Button>
                            ) : (
                              <Button
                                variant="transparent"
                                size="sm"
                                className="p-0 shadow-none"
                                title="Add to material requestes"
                                onClick={() =>
                                  handleAddOrRemoveToMaterialRequestes(item)
                                }
                              >
                                <MdOutlineAddBox
                                  className="text-success"
                                  size={23}
                                />
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={7} className="text-center">
                        {Translate('No data found')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </>
            )}
          </Table>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {!isSaving ? (
          <Button variant="danger" size="sm" onClick={onHide}>
            {Translate('Cancel')}
          </Button>
        ) : null}
        {bomDataLength > 0 && (
          <Button
            variant="success"
            size="sm"
            onClick={handleSubmit}
            disabled={isSaving}
            className="d-flex flex-row gap-1 align-items-center justify-content-center"
          >
            {isSaving ? (
              <>
                <Spinner
                  style={{
                    width: 15,
                    height: 15
                  }}
                />{' '}
                {Translate('Saving')}
              </>
            ) : (
              Translate('Save')
            )}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

WorkOrderBomModal.propTypes = {
  onHide: PropTypes.func,
  workOrderId: PropTypes.string,
  workOrderDetailId: PropTypes.string,
  setTabApiCallCompleted: PropTypes.func,
  WorkOrderDetailsArray: PropTypes.array,
  stockId: PropTypes.string
};
