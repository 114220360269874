import { React } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import GLEntryItemsTable from '../Common/Tables/GLEntryItemsTable';
import { useContext } from 'react';
import { AuthWizardContext } from 'context/Context';
import PersonName from '../Common/ViewPageDetails/PersonName';
import Badges from '../Common/ViewPageDetails/Badges';
import Details from '../Common/ViewPageDetails/Details';
import {
  FaAddressCard,
  FaCentercode,
  FaDatabase,
  FaPhoneAlt,
  FaUser
} from 'react-icons/fa';
import { BiLogoGmail } from 'react-icons/bi';
import { BsCoin } from 'react-icons/bs';
import ShowMemo from '../Common/ViewPageDetails/ShowMemo';
import QuillPreviewContainer from 'components/QuillPreviewContainer/QuillPreviewContainer';
import EntriesPopUpLineItemAccordionView from 'components/entries-pop-up-form/EntriesPopUpLineItemAccordionView/EntriesPopUpLineItemAccordionView';
import { FaWarehouse } from 'react-icons/fa6';

function PurchaseInvoiceView({ purchaseInvoiceData, isFixedAsset }) {
  const { user } = useContext(AuthWizardContext);
  const enableBatchSection = !!+user?.branch?.inventory_settings?.enable_batch;

  return (
    <Container className="mt-3 ps-3 pe-3 voucher-inquiry-view-wrapper">
      <Row>
        <Col lg={6} md={6} sm={12} className="p-0">
          {purchaseInvoiceData?.supplier_name ? (
            <PersonName value={purchaseInvoiceData?.supplier_name} />
          ) : null}
          {purchaseInvoiceData?.reference && (
            <Badges label={purchaseInvoiceData?.reference} bg={'danger'} />
          )}
          <div className="mt-2">
            {purchaseInvoiceData?.customer_id && (
              <Details
                label={'Customer'}
                value={purchaseInvoiceData?.customer_name}
                className={'mb-2 fs--1'}
                icon={<FaUser className="me-1 text-success" size={15} />}
              />
            )}
            {purchaseInvoiceData?.cost_center_name && (
              <Details
                label={'Cost Center'}
                value={purchaseInvoiceData?.cost_center_name}
                className={'mb-2 fs--1'}
                icon={<FaCentercode className="me-1 text-warning" size={15} />}
              />
            )}
            {purchaseInvoiceData?.warehouse_name && (
              <Details
                label={'Warehouse'}
                value={purchaseInvoiceData.warehouse_name}
                icon={<FaWarehouse className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {purchaseInvoiceData?.phone && (
              <Details
                label={'Phone Number'}
                value={purchaseInvoiceData?.phone}
                icon={<FaPhoneAlt className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {purchaseInvoiceData?.email && (
              <Details
                label={'Email Address'}
                value={purchaseInvoiceData?.email}
                icon={<BiLogoGmail className="me-1 text-danger" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {purchaseInvoiceData.supplier_reference && (
              <Details
                label={'Supplier Reference'}
                value={purchaseInvoiceData.supplier_reference}
                icon={<FaAddressCard className="me-1 text-danger" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {purchaseInvoiceData?.fa_stock_name && (
              <Details
                label={'Asset'}
                value={purchaseInvoiceData.fa_stock_name}
                icon={<FaDatabase className="me-1 text-success" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="p-0">
          <div className="mt-sm-2 mt-md-3">
            <Details
              label={
                purchaseInvoiceData?.tax_included &&
                purchaseInvoiceData?.tax_included === 1
                  ? 'Amount (Inc.Tax)'
                  : 'Amount'
              }
              value={purchaseInvoiceData?.amount_formatted}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            {parseFloat(purchaseInvoiceData?.total_additional_cost_amount) >
              0 && (
              <Details
                label={'Additional Cost Amount'}
                value={
                  purchaseInvoiceData?.total_additional_cost_amount_formatted
                }
                className={'mb-2 fs--1'}
                icon={<BsCoin className="me-1 text-dark" size={15} />}
              />
            )}
            <Details
              label={'Paid Amount'}
              value={purchaseInvoiceData?.alloc_formatted}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />

            <Details
              label={'Balance Amount'}
              value={purchaseInvoiceData?.balance_formatted}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Tax'}
              value={purchaseInvoiceData?.total_tax_formatted}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            {parseFloat(purchaseInvoiceData?.additional_cost_amount) > 0 && (
              <Details
                label={'Total Additional Cost Tax'}
                value={
                  purchaseInvoiceData?.additional_cost_tax_amount_formatted
                }
                className={'mb-2 fs--1'}
                icon={<BsCoin className="me-1 text-dark" size={15} />}
              />
            )}
            <Details
              label={'Round Off Amount'}
              value={purchaseInvoiceData?.round_off_amount_formatted}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Discount'}
              value={purchaseInvoiceData?.total_discount_amount_formatted}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12} md={12} sm={12} className="p-0 mt-2">
          {Array.isArray(purchaseInvoiceData?.details) &&
          purchaseInvoiceData.details.length > 0 ? (
            <div className="mt-3">
              <EntriesPopUpLineItemAccordionView
                type={isFixedAsset ? 'FIXED-ASSET' : 'PI'}
                entries={purchaseInvoiceData.details}
                additionalCostIncluded={Boolean(
                  purchaseInvoiceData?.additional_cost_amount > 0
                )}
                taxIncluded={Boolean(purchaseInvoiceData?.tax_included)}
                enableTaxGroup={
                  !!(
                    purchaseInvoiceData?.details?.[0]?.tax_group_id?.length > 0
                  )
                }
                enableBatchSection={enableBatchSection}
                batchEnabledItemFound={
                  !!purchaseInvoiceData.details.find(entry =>
                    Boolean(entry?.has_batch_inventory)
                  )
                }
              />
            </div>
          ) : null}

          {Array.isArray(purchaseInvoiceData?.gl_items) &&
          purchaseInvoiceData?.gl_items.length > 0 ? (
            <div className="mt-4">
              <GLEntryItemsTable glEntries={purchaseInvoiceData.gl_items} />
            </div>
          ) : null}
        </Col>
      </Row>

      {purchaseInvoiceData?.terms_and_conditions?.length > 0 ? (
        <Row className="mt-5">
          <Col className="px-0 pb-3">
            <QuillPreviewContainer
              title="Terms & Conditions :"
              value={purchaseInvoiceData.terms_and_conditions}
              dashedBorder
            />
          </Col>
        </Row>
      ) : null}

      {purchaseInvoiceData?.memo && (
        <ShowMemo memo={purchaseInvoiceData?.memo} />
      )}
    </Container>
  );
}

PurchaseInvoiceView.propTypes = {
  purchaseInvoiceData: PropTypes.any,
  isFixedAsset: PropTypes.bool
};

export default PurchaseInvoiceView;
