import SelectAccountDescriptions from 'components/form/SelectAccountDescriptions';
import SelectSubLedger from 'components/form/SelectSubLedger';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import DateRangePicker from './DateRangePicker';
import DatePickerFilter from './DatePickerFilter';
import DateTimePickerFilter from './DateTimePickerFilter';
import SelectVoucherTransType from 'components/form/SelectVoucherTransType';
import SelectBranch from 'components/form/SelectBranch';
import SelectCustomerGroup from 'components/form/SelectCustomerGroup';
import SelectCustomer from 'components/form/SelectCustomer';
import SelectTransactionTypes from 'components/form/SelectTransactionTypes';
import SelectCategory from 'components/form/SelectCategory';
import SubCategoryFilter from './SubCategoryFilter';
import SelectFilterItem from 'module/Common/Search/SelectFilterItem';
import SelectUser from 'components/form/SelectUser';
import SelectCostCenter from 'components/form/SelectCostCenter';
import SelectBankAccount from 'components/form/SelectBankAccount';
import SelectSalesman from 'components/form/SelectSalesman';
import SelectWarehouse from 'components/form/SelectWarehouse';
import SelectSalesType from 'components/form/SelectSalesType';
import CustomDropDown from './CustomDropDown';
import SelectSupplier from 'components/form/SelectSupplier';
import SelectCurrency from 'module/Common/Search/SelectCurrency';
import SelectMultiYear from 'components/form/SelectMultiYear';
import SelectMultiMonth from 'components/form/SelectMultiMonth';
import CheckBoxFilter from './CheckBoxFilter';
import SelectWorkOrder from 'module/Common/Search/SelectWorkOrder';
import SelectRole from 'components/form/SelectRole';
import PropTypes from 'prop-types';
const FilterComponentData = ({
  filterData,
  feildName,
  modal,
  setFormData,
  formData,
  filterForm,
  year,
  handleFieldChange
}) => {
  const Translate = useAxisproTranslate();
  const [dateTimePickerName, setDateTimePickerName] = useState(null);
  const datePickersRefs = useRef([]);

  const handleMultiSelect = (selectedOptions, action) => {
    const { name } = action;
    if (['years', 'months'].includes(name)) {
      const data = selectedOptions.map(item => item.label).join(',');
      setFormData(prev => ({
        ...prev,
        [name]: data,
        [`${name}_ref`]: selectedOptions
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: selectedOptions
      }));
    }
  };
  return (
    <>
      {(feildName === 'ledger' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <SelectAccountDescriptions
                name={item.name}
                value={formData[item.name + '_ref'] ?? ''}
                handleFieldChange={handleFieldChange}
                keyName="code"
                required
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'sub_ledger' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <SelectSubLedger
                name={item.name}
                value={formData[item.name + '_ref'] ?? ''}
                handleFieldChange={handleFieldChange}
                ledger_id={formData['account_code'] ?? ''}
                required
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'dateRange' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading ?? 'Date Range')}</Form.Label>
              <DateRangePicker
                formData={formData}
                setFormData={setFormData}
                dateRange_form={item}
                selectThisMonth={item?.selectThisMonth ?? false}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'date' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <DatePickerFilter
                datePickersRefs={datePickersRefs}
                formData={formData}
                handleFieldChange={handleFieldChange}
                index={key}
                item={item}
                setFormData={setFormData}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'dateAndTimePicker' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <DateTimePickerFilter
                dateTimePickerName={dateTimePickerName}
                setDateTimePickerName={setDateTimePickerName}
                formData={formData}
                handleFieldChange={handleFieldChange}
                item={item}
                key={key}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'voucher_trans' &&
        filterData?.map((items, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(items.Heading)}</Form.Label>
              <SelectVoucherTransType
                name={items.name}
                keyName="slug"
                onChange={handleFieldChange}
                value={formData[items.name + '_ref'] ?? ''}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'branch' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <SelectBranch
                name={item.name}
                value={formData[item.name + '_ref'] ?? ''}
                handleFieldChange={handleFieldChange}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'customer_group' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <SelectCustomerGroup
                name={item.name}
                value={formData[item.name + '_ref'] ?? ''}
                handleFieldChange={handleFieldChange}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'customer' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <SelectCustomer
                name={item.name}
                value={formData[item.name + '_ref'] ?? ''}
                handleFieldChange={handleFieldChange}
                showCustomerDetails={item?.showPerson}
                enableBranchTransaction={item?.intraBranch}
                customer_group_id={formData[item?.customer_group_name]}
                advancedSearch
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'trans_type' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <SelectTransactionTypes
                name={item.name}
                keyName="slug"
                handleFieldChange={handleFieldChange}
                value={formData[`${item.name}_ref`] ?? ''}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'category' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <SelectCategory
                name={item.name}
                value={formData[item.name + '_ref'] ?? ''}
                handleFieldChange={handleFieldChange}
                fixedAsset={item?.fixedAsset ? true : false}
                filter_keys={
                  item?.filterKey && {
                    ...item?.filterKey
                  }
                }
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'subCategory' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Label>{Translate(item.Heading)}</Form.Label>
            <SubCategoryFilter
              filterForm={filterForm}
              formData={formData}
              item={item}
              setFormData={setFormData}
            />
          </Col>
        ))) ||
        ''}

      {(feildName === 'item' &&
        filterData?.map((items, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(items.Heading)}</Form.Label>
              <SelectFilterItem
                value={formData[items.name + '_ref'] ?? ''}
                handleFieldChange={handleFieldChange}
                name={items.name}
                stockItemList
                filter_keys={
                  items?.filterKey && {
                    ...items?.filterKey
                  }
                }
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'user' &&
        filterData?.map((items, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(items.Heading)}</Form.Label>
              <SelectUser
                value={formData[items.name + '_ref'] ?? ''}
                handleFieldChange={handleFieldChange}
                name={items.name}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'cost_center' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <SelectCostCenter
                value={formData[`${item.name}_ref`] ?? ''}
                name={item.name}
                handleFieldChange={handleFieldChange}
                style={{ borderRadius: 'none' }}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'bankAccount' &&
        filterData?.map((items, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(items.Heading)}</Form.Label>
              <SelectBankAccount
                name={items.name}
                value={formData[items.name + '_ref'] ?? ''}
                handleFieldChange={handleFieldChange}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'salesman' &&
        filterData?.map((items, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(items.Heading)}</Form.Label>
              <SelectSalesman
                name={items.name}
                value={formData[items.name + '_ref'] ?? ''}
                handleFieldChange={handleFieldChange}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'wareHouse' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <SelectWarehouse
                name={item.name}
                value={formData?.[item.name + '_ref'] ?? ''}
                handleFieldChange={handleFieldChange}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'sales_type' &&
        filterData?.map((items, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(items.Heading)}</Form.Label>
              <SelectSalesType
                name={items.name}
                value={formData[items.name + '_ref'] ?? ''}
                handleFieldChange={handleFieldChange}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'customSelect' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <CustomDropDown
                name={item.name}
                value={formData[item.name + '_ref'] ?? ''}
                onChange={handleFieldChange}
                options={item?.option}
                isMulti={item?.isMulti}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'supplier' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <SelectSupplier
                name={item.name}
                value={formData[item.name + '_ref'] ?? ''}
                handleFieldChange={handleFieldChange}
                showSupplierDetails={item?.showPerson ?? false}
                enableBranchTransaction={item?.interBranch}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'textBox' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item?.Heading)}</Form.Label>
              <Form.Control
                type="text"
                name={item?.name}
                value={formData[item.name] ?? ''}
                onChange={handleFieldChange}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'account_code' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <SelectAccountDescriptions
                keyName="code"
                name={item.name}
                value={formData[item.name + '_ref'] ?? ''}
                handleFieldChange={handleFieldChange}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'currency_code' &&
        filterData?.map((items, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(items.Heading)}</Form.Label>
              <SelectCurrency
                name={items.name}
                handleFieldChange={handleFieldChange}
                value={formData[items.name + '_ref'] ?? ''}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'years' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <SelectMultiYear
                value={formData[item.name + '_ref'] ?? ''}
                name={item.name}
                yearData={year}
                handleFieldChange={handleMultiSelect}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'months' &&
        filterData?.map((item, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(item.Heading)}</Form.Label>
              <SelectMultiMonth
                value={formData[item.name + '_ref'] ?? ''}
                name={item.name}
                handleFieldChange={handleMultiSelect}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'checkBox' &&
        filterData?.map((item, key) => {
          return (
            <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
              <CheckBoxFilter
                formData={formData}
                item={item}
                key={key}
                setFormData={setFormData}
              />
            </Col>
          );
        })) ||
        ''}

      {(feildName === 'work_order' &&
        filterData?.map((items, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(items.Heading)}</Form.Label>
              <SelectWorkOrder
                name={items.name}
                handleFieldChange={handleFieldChange}
                value={formData[items.name + '_ref'] ?? ''}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}

      {(feildName === 'role' &&
        filterData?.map((items, key) => (
          <Col md={modal ? 4 : 6} lg={modal ? 4 : 2} key={key}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate(items.Heading)}</Form.Label>
              <SelectRole
                name={items.name}
                handleFieldChange={handleFieldChange}
                value={formData[items.name + '_ref'] ?? ''}
              />
            </Form.Group>
          </Col>
        ))) ||
        ''}
    </>
  );
};
FilterComponentData.propTypes = {
  filterData: PropTypes.any,
  feildName: PropTypes.string,
  modal: PropTypes.bool,
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  filterForm: Object.any,
  year: PropTypes.array,
  handleFieldChange: PropTypes.func
};
export default FilterComponentData;
