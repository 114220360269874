import { React, useState } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Total from 'module/Common/Total';
import QuillPreviewContainer from 'components/QuillPreviewContainer/QuillPreviewContainer';
import EstimationLineItemsAccordionView from 'module/Production/Estimation/components/estimation-lineItems-accordion-view/EstimationLineItemsAccordionView';
import EstimationLineItemClone from 'module/Production/Estimation/components/estimation-lineItems-accordion-view/EstimationLineItemClone';
import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

function EstimationView({ estimationEntryData, setEstimationEntryData }) {
  const [enableClone, setEnableClone] = useState(false);
  const [cloneData, setCloneData] = useState({});
  const [onUpdate, setOnUpdate] = useState(false);
  const Translate = useAxisproTranslate();
  const setLineItemData = data => {
    return Array.isArray(data)
      ? data.reduce((newCostsResult, costEntry) => {
          let newCostEntryData = {
            stock_id: costEntry?.stock_id ?? '',
            title: costEntry?.title ?? '',
            title_ref: {
              label: costEntry?.title ?? ''
            },
            description: costEntry?.description ?? '',
            quantity: costEntry?.quantity ?? '',
            amount: costEntry?.amount ?? '',
            amount_formatted: costEntry?.amount_formatted ?? '',
            total_amount_formatted: costEntry?.total_amount_formatted ?? '',
            unit_name_ref: costEntry?.unit_name ?? ''
          };

          newCostsResult.push(newCostEntryData);
          return newCostsResult;
        }, [])
      : [];
  };

  const entries = Array.isArray(estimationEntryData?.details)
    ? estimationEntryData.details.reduce((newResult, entry) => {
        let newData = {
          description: entry?.description ?? '',
          quantity: entry?.quantity ?? '',
          stock_id: entry?.stock_id ?? '',
          title: entry?.title ?? '',
          title_ref: {
            label: entry?.title ?? ''
          },
          costs_net_amount_formatted: entry?.costs_net_amount_formatted ?? '',
          additional_costs_net_amount_formatted:
            entry?.additional_costs_net_amount_formatted ?? '',
          unit_name_ref: entry?.unit_name ?? '',
          dimension_unit: entry?.dimension_unit ?? '',
          dim_length: entry?.dim_length ?? '',
          dim_width: entry?.dim_width ?? '',
          dim_height: entry?.dim_height ?? '',
          weight: entry?.weight ?? '',
          costs: setLineItemData(entry?.costs),
          additional_costs: setLineItemData(entry?.additional_costs),
          id: entry?.id
        };
        newResult.push(newData);
        return newResult;
      }, [])
    : [];

  const handleCloneLineItem = (status = false, data) => {
    setEnableClone(status);
    setCloneData(data);
  };

  const submitClonedLineItem = async () => {
    try {
      setOnUpdate(true);
      const { details, id, ...rest } = estimationEntryData;
      const newFormData = {
        ...rest,
        details: [...details, { ...cloneData, id: null }]
      };

      const { data } = await axios.put(`crm/estimation/${id}`, newFormData);
      setEstimationEntryData(data?.data);
      setCloneData({});
      handleCloneLineItem(false);
    } catch (error) {
      let errorMessage =
        'Something went wrong, please refresh the page and try again.';
      if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      showToast(Translate(errorMessage), 'error');
    } finally {
      setOnUpdate(false);
    }
  };

  return (
    <Container className="my-3 p-0 voucher-inquiry-view-wrapper">
      <div className="mt-2">
        {Array.isArray(entries) && entries?.length > 0 ? (
          <EstimationLineItemsAccordionView
            entries={entries}
            handleCloneLineItem={handleCloneLineItem}
          />
        ) : null}

        <Total subTotal={estimationEntryData?.total_amount} />
      </div>

      {estimationEntryData?.notes ? (
        <div className="mt-4">
          <QuillPreviewContainer
            title="Notes :"
            value={estimationEntryData.notes}
            dashedBorder
          />
        </div>
      ) : null}

      <EstimationLineItemClone
        show={enableClone}
        handleCloneLineItem={handleCloneLineItem}
        submitClonedLineItem={submitClonedLineItem}
        cloneData={cloneData}
        onUpdate={onUpdate}
      />
    </Container>
  );
}

EstimationView.propTypes = {
  estimationEntryData: PropTypes.any,
  setEstimationEntryData: PropTypes.func
};

export default EstimationView;
