import { React, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import {
  Badge,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import { BsReceipt } from 'react-icons/bs';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useBreakpoints } from 'hooks/useBreakpoints';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import TransactionTable from '../Common/Tables/TransactionTable';
import PDFView from 'components/PDFViewerButton/PDFView';
import Badges from '../Common/ViewPageDetails/Badges';
import PersonName from '../Common/ViewPageDetails/PersonName';
import MainDetails from '../Common/ViewPageDetails/MainDetails';
import Reference from '../Common/ViewPageDetails/Reference';
import FooterDetails from '../Common/ViewPageDetails/FooterDetails';
import RentalContractView from './RentalContractView';
import AssetItemTabView from '../Common/AssetItemTabView/AssetItemTabView';
import ContractStatusDropDownButton from '../Common/ContractStatusDropDownButton';
import { RxDotFilled } from 'react-icons/rx';
import ActivityLog from 'components/activity-log/ActivityLog';
import RentalMakeInvoiceModal from './RentalMakeInvoiceModal';
import AdditionalInvoice from './AdditionalInvoice';
import RentalContractReturnModal from '../Common/RentalContract/RentalContractReturnModal';
function RentalContractTabView({
  rentalContractData,
  loading,
  voidScreen,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData,
  onHide,
  handlePaymentSuccess
}) {
  const Translate = useAxisproTranslate();
  const navigate = useNavigate();
  const { breakpoints } = useBreakpoints();
  const [showMakeRentalToInvoice, setMakeRentalToInvoice] = useState(false);
  const [makeAdditionalInvoice, setMakeAdditionalInvoice] = useState(false);
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [upload, setUpload] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const permission = {
    show_rc: getAcodaxPermissionSlug('RC', 'view'),
    show_cs: getAcodaxPermissionSlug('CS', 'show'),
    print_rc: getAcodaxPermissionSlug('RC', 'print'),
    create_rc: getAcodaxPermissionSlug('RC', 'create'),
    update_rc: getAcodaxPermissionSlug('RC', 'update'),
    send_mail_rc: getAcodaxPermissionSlug('RC', 'send-mail'),
    void_rc: getAcodaxPermissionSlug('VOID', 'TRANSACTION')
  };
  const rentalContractDetails = rentalContractData?.details?.[0] ?? null;
  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };
  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom justify-content-between'
              : 'border-bottom justify-content-between'
          }
        >
          <>
            <div className="d-flex">
              <div className="d-flex m-0 align-items-center">
                <BsReceipt size={28} className="me-2 text-dark" />
                <h5 className="mb-0 d-flex text-uppercase view-page-header">
                  {Translate('Rental Contract')}
                </h5>
              </div>
              <ContractStatusDropDownButton
                data={rentalContractData}
                rentalContract
              />
              <Badge
                className="text-uppercase p-0 ms-1 d-flex align-items-center ps-2 pe-3 rental-contract-status-badge"
                bg={
                  rentalContractData?.rental_status === 'pending'
                    ? 'warning'
                    : rentalContractData?.rental_status === 'rented_out'
                    ? 'danger'
                    : rentalContractData?.rental_status === 'returned'
                    ? 'success'
                    : 'danger'
                }
              >
                <RxDotFilled size={18} />{' '}
                {rentalContractData?.rental_status?.replace(/_/g, ' ')}
              </Badge>
              {voidTransactionReport && (
                <Badges
                  label={'Voided'}
                  className="ms-1  p-2 text-uppercase"
                  bg={'danger'}
                />
              )}
            </div>
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center ">
              {rentalContractData?.rental_status == 'rented_out' && (
                <RentalContractReturnModal
                  data={rentalContractData}
                  getData={fetchData}
                  rentalContract
                />
              )}
              {voidScreen || offCanvas ? (
                ''
              ) : (
                <MoreButton
                  // renewedPath={`/fixed-assets/rental-contracts/edit/${
                  //   rentalContractData?.id
                  // }?${createSearchParams({
                  //   ...allQueryParams,
                  //   renewed: true
                  // })}`}
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  emailPermission={permission?.send_mail_rc}
                  clonePath={
                    permission?.create_rc
                      ? '/fixed-assets/rental-contracts/add?clone_item=' +
                        rentalContractData?.id
                      : undefined
                  }
                  type="RCT"
                  rentalToInvoice={!rentalContractData?.rental_invoice?.id}
                  setMakeRentalToInvoice={setMakeRentalToInvoice}
                  additionalInvoice
                  setMakeAdditionalInvoice={setMakeAdditionalInvoice}
                  voidTransactionData={
                    permission?.void_rc
                      ? {
                          id: rentalContractData?.id,
                          trans_type: 'RCT',
                          callBack: () => {
                            navigate('/fixed-assets/rental-contracts/info');
                            typeof getData === 'function' && getData(1);
                          }
                        }
                      : undefined
                  }
                />
              )}
              {voidScreen || offCanvas ? (
                ''
              ) : permission?.update_rc &&
                !rentalContractData?.rental_invoice?.reference ? (
                <EditButton
                  to={`/fixed-assets/rental-contracts/edit/${
                    rentalContractData?.id
                  }?${createSearchParams({
                    ...allQueryParams
                  })}`}
                />
              ) : (
                ''
              )}
              {!voidTransactionReport && (
                <ViewPagePrintButton
                  variant="danger"
                  className="text-white"
                  style={{ boxShadow: 'none' }}
                  url={`sales/rental-contract/print/${rentalContractData?.id}`}
                  pageTitle="Rental contract print view"
                />
              )}
              {offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea || onHide}
                />
              ) : (
                ''
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex flex-wrap justify-content-between">
            <div>
              {rentalContractData?.customer_name ? (
                <PersonName
                  label={'Customer Name'}
                  value={rentalContractData?.customer_name}
                  view
                  permission={permission?.show_cs}
                  setCustomerDetailsArea={setCustomerDetailsArea}
                />
              ) : null}
              {rentalContractData?.contract_date_formatted ? (
                <MainDetails
                  label={'Rental Contract Date'}
                  value={rentalContractData?.contract_date_formatted}
                />
              ) : null}
              {rentalContractDetails?.item_warehouse_name ? (
                <MainDetails
                  label={'Warehouse'}
                  value={rentalContractDetails?.item_warehouse_name}
                />
              ) : null}
            </div>
            <div>
              {rentalContractData?.reference ? (
                <Reference
                  offCanvas={offCanvas}
                  voidTransactionReport={voidTransactionReport}
                  to={`/fixed-assets/rental-contracts/info/${rentalContractData?.id}`}
                  value={rentalContractData?.reference}
                />
              ) : null}
              {rentalContractData?.amount_formatted ? (
                <MainDetails
                  label={'Total Rental Contract Amount'}
                  value={rentalContractData?.amount_formatted}
                />
              ) : null}
              {rentalContractData?.total_invoiced_amount_formatted ? (
                <MainDetails
                  label={'Total Invoiced Amount'}
                  value={rentalContractData?.total_invoiced_amount_formatted}
                />
              ) : null}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={rentalContractData.trans_type}
                      transId={rentalContractData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar
                  className={
                    voidScreen || offCanvas
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <RentalContractView rentalContractData={rentalContractData} />
                </SimpleBar>
              </Tab>
              {rentalContractData?.details?.length > 0 && (
                <Tab eventKey="Asset item" title={Translate('Asset Item')}>
                  <SimpleBar
                    className={
                      voidScreen || offCanvas
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    <AssetItemTabView rentalContractData={rentalContractData} />
                  </SimpleBar>
                </Tab>
              )}
              {rentalContractData?.sales_invoices &&
                rentalContractData?.sales_invoices.length > 0 && (
                  <Tab eventKey="transactions" title={Translate('Invoices')}>
                    <SimpleBar
                      className={
                        voidScreen || offCanvas
                          ? 'simplebar-view-page-style'
                          : 'simplebar-view-page-style-with-container'
                      }
                    >
                      <TransactionTable
                        data={rentalContractData}
                        enablePaymentOption
                        fetchData={fetchData}
                        rentalContract
                        handlePaymentSuccess={handlePaymentSuccess}
                      />
                    </SimpleBar>
                  </Tab>
                )}
              {rentalContractData?.media &&
              rentalContractData?.media?.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar
                    className={
                      Math.round(rentalContractData.balance) === 0 ||
                      voidScreen ||
                      offCanvas
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    <AttachementsView
                      data={rentalContractData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              {rentalContractData?.id ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar
                    className={'simplebar-view-page-tab-height-for-rct'}
                  >
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`sales/rental-contract/print/${rentalContractData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}

              <Tab eventKey="activityLog" title={Translate('Activity Log')}>
                <SimpleBar className="simplebar-view-page-tab-height-for-rct">
                  {key === 'activityLog' && rentalContractData?.id && (
                    <ActivityLog subjectID={rentalContractData?.id} />
                  )}
                </SimpleBar>
              </Tab>
            </Tabs>
          </Container>
        </Card.Body>
        <FooterDetails
          created_at={rentalContractData?.created_at}
          created_by={rentalContractData?.created_user_full_name}
        />
      </Card>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={{ value: rentalContractData?.customer_id }}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      {rentalContractData?.id && (
        <FileUpload
          show={upload}
          onHide={() => setUpload(false)}
          fetchData={fetchData}
          type={'RCT'}
          id={rentalContractData.id}
          setKey={setKey}
        />
      )}
      {showMakeRentalToInvoice && (
        <RentalMakeInvoiceModal
          show={showMakeRentalToInvoice}
          setShow={setMakeRentalToInvoice}
          data={rentalContractData}
          fetchData={fetchData}
        />
      )}
      {makeAdditionalInvoice && (
        <AdditionalInvoice
          show={makeAdditionalInvoice}
          setShow={setMakeAdditionalInvoice}
          data={rentalContractData}
          fetchData={fetchData}
        />
      )}
      <SendEmail
        url={'/fixedasset/rental-contracts/export-sendmail?mode=pdf'}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
      />
    </>
  );
}

RentalContractTabView.propTypes = {
  rentalContractData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func,
  onHide: PropTypes.func,
  handlePaymentSuccess: PropTypes.func
};

export default RentalContractTabView;
