import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SelectBankAccount from 'components/form/SelectBankAccount';

function PaymentChequeTable({
  data,
  handleFieldChange,
  formError,
  handleChange
}) {
  const Translate = useAxisproTranslate();

  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th className="text-center text-dark fw-semi-bold px-2">
              {Translate('S/L')}
            </th>
            <th
              className="text-center text-dark fw-semi-bold px-2 d-flex"
              style={{ width: 'max-content' }}
            >
              {Translate('Is PDC')}
            </th>
            <th className="text-center text-dark fw-semi-bold">
              {Translate('Date')}
            </th>
            <th className="text-center text-dark fw-semi-bold">
              {Translate('Deposit To')}
            </th>
            <th className="text-center text-dark fw-semi-bold">
              {Translate('Bank Name')}
            </th>
            <th className="text-center text-dark fw-semi-bold">
              {Translate('Trans Ref')}
            </th>
            <th className="text-center text-dark fw-semi-bold">
              {Translate('Cheque Date')}
            </th>
            <th className="text-center text-dark fw-semi-bold">
              {Translate('Cheque No')}
            </th>
            <th className="text-center text-dark fw-semi-bold">
              {Translate('Amount')}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="p-1 text-center text-dark">{index + 1}</td>
              <td className="p-1 mt-2 text-dark h-100 d-flex justify-content-center align-items-center">
                <Form.Check
                  type="checkbox"
                  value={row?.is_pdc}
                  checked={row?.is_pdc === 1 ? true : false}
                  onChange={e => handleFieldChange(e, index)}
                />
              </td>
              <td className="p-1 text-dark">
                <AppDatePicker
                  name="trans_date"
                  value={row.trans_date}
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  onChange={e => {
                    handleFieldChange(e, index);
                  }}
                  showDefaultDate
                  calendarClassName="position-relative"
                />
              </td>
              <td className="p-1 text-dark" style={{ width: '230px' }}>
                <SelectBankAccount
                  value={row.deposit_to_ref}
                  error={formError.deposit_to}
                  handleFieldChange={(e, action) => {
                    handleChange(e, action, row, index);
                  }}
                  menuPosition="fixed"
                />
              </td>
              <td className="p-1 text-dark" style={{ width: '180px' }}>
                <Form.Control
                  type="text"
                  name="cheque_bank"
                  value={row.cheque_bank}
                  onChange={e => {
                    handleFieldChange(e, index);
                  }}
                />
              </td>
              <td className="p-1 text-dark">
                <Form.Control
                  type="text"
                  name="trans_ref"
                  value={row.trans_ref}
                  onChange={e => {
                    handleFieldChange(e, index);
                  }}
                />
              </td>
              <td className="p-1 text-dark">
                <AppDatePicker
                  name="cheque_date"
                  value={row.cheque_date}
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  onChange={e => {
                    handleFieldChange(e, index);
                  }}
                  showDefaultDate
                  calendarClassName="position-relative"
                />
              </td>
              <td className="p-1 text-dark">
                <Form.Control
                  type="number"
                  name="cheque_number"
                  value={row.cheque_number}
                  onChange={e => {
                    handleFieldChange(e, index);
                  }}
                  min={0}
                  onKeyDown={e =>
                    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                  }
                />
              </td>
              <td className="p-1 text-dark">
                <Form.Control
                  type="number"
                  name="amount"
                  id={`amount-${index}`}
                  value={row.amount}
                  onChange={e => {
                    handleFieldChange(e, index);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PaymentChequeTable;

PaymentChequeTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      trans_date: PropTypes.string.isRequired,
      deposit_to_ref: PropTypes.object.isRequired,
      cheque_bank: PropTypes.string.isRequired,
      trans_ref: PropTypes.object.isRequired,
      cheque_date: PropTypes.string.isRequired,
      cheque_number: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired
    })
  ).isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  formError: PropTypes.object,
  handleChange: PropTypes.func
};
