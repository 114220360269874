import { React } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { IoMdCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';

function UnUsedCreditsFormTableItem({
  advance_data,
  index,
  handleAdvanceReceiptAmount,
  dueAmount,
  allocatedAmount,
  ClearAllocation
}) {
  return (
    <tr>
      <td className="fs--1 text-dark p-1 text-center">{index + 1}</td>
      <td className="fs--1 text-dark p-1 text-center">
        {advance_data.reference}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {advance_data.trans_type_name}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {advance_data.trans_date_formatted}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {advance_data?.cost_center_name ? advance_data.cost_center_name : '-'}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {advance_data.amount_formatted}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {advance_data.balance_amount_formatted}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        <Form.Control
          size="sm"
          className="me-1"
          type="number"
          step="0.01"
          min={0}
          disabled={dueAmount === 0 && !advance_data.payment_amount}
          max={parseFloat(advance_data.balance_amount)}
          name="amount"
          onChange={e => handleAdvanceReceiptAmount(e, index)}
          value={
            advance_data.payment_amount
              ? parseFloat(advance_data.payment_amount)
              : ''
          }
        />
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {!advance_data.payment_amount ? (
          <IoMdCheckmarkCircle
            size={18}
            title="Add Amount"
            className="text-success cursor-pointer "
            onClick={() => allocatedAmount(index)}
          />
        ) : (
          <IoMdCloseCircle
            size={18}
            title="clear"
            className="text-danger cursor-pointer"
            onClick={() => {
              ClearAllocation(index);
            }}
          />
        )}
      </td>
    </tr>
  );
}

UnUsedCreditsFormTableItem.propTypes = {
  advance_data: PropTypes.object,
  index: PropTypes.number,
  handleAdvanceReceiptAmount: PropTypes.func,
  dueAmount: PropTypes.number,
  allocatedAmount: PropTypes.func,
  ClearAllocation: PropTypes.func
};

export default UnUsedCreditsFormTableItem;
