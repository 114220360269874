import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { MdOutlinePayments } from 'react-icons/md';
import { FcPlus } from 'react-icons/fc';
import { FaTrash } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';
import { AuthWizardContext } from 'context/Context';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import SelectBankAccount from 'components/form/SelectBankAccount';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import { paymentChequeScreenFormKeys } from 'helpers/formKeys';
import { convertDateFormat } from 'helpers/utils';
import SimpleBar from 'simplebar-react';
import PaymentChequeTable from './Tables/PaymentChequeTable';
import moment from 'moment';

function PaymentChequeScreen({
  show,
  person,
  invoiceData,
  onHide,
  fetchData,
  dueAmount,
  onPaymentSuccess
}) {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  let defaultBankAccount = user?.branch ?? '';
  const [formData, setFormData] = useState([
    {
      ...paymentChequeScreenFormKeys,
      trans_date: convertDateFormat(new Date(), 'YYYY-MM-DD')
    }
  ]);
  const [formError, setFormError] = useState({});
  const [saving, setSaving] = useState(false);
  const [noOfCheques, setNoOfCheques] = useState(1);
  const [period, setPeriod] = useState('custom');
  const [amountToBeSplit, setAmountToBeSplit] = useState(dueAmount || 0);
  const [view, setView] = useState(false);
  const baseCurrency = user?.branch?.base_currency ?? '';

  const AddNewPayment = () => {
    let total = 0;
    formData.map(items => {
      total = total + items.allocs.amount;
    });
    let balanceAmount = dueAmount - total;

    setFormData(prev => [
      ...prev,
      {
        ...paymentChequeScreenFormKeys,
        amount: balanceAmount > 0 && balanceAmount,
        customer_id: invoiceData.customer_id,
        cost_center_id: invoiceData.cost_center_id ?? '',
        allocs: {
          customer_id: invoiceData.customer_id,
          voucher_id: invoiceData.voucher_id,
          amount: balanceAmount > 0 && balanceAmount,
          trans_type: 'SI'
        }
      }
    ]);
  };

  const removeColumn = index => {
    setFormData(prevFormData => {
      // Create a new array reference before updating the state
      const newArray = [...prevFormData];
      // Use splice directly on the new array to remove the item at the specified index
      newArray.splice(index, 1);
      const totalAmountToAllocate = parseFloat(dueAmount);
      const newChequesCount = newArray.length;
      const amountPerCheque = totalAmountToAllocate / newChequesCount;

      // Update the amount for all remaining cheques
      const updatedFormData = newArray.map(item => ({
        ...item,
        amount: amountPerCheque,
        allocs: {
          ...item.allocs,
          amount: amountPerCheque
        }
      }));

      // Return the modified array to update the state
      return updatedFormData;
    });
  };
  const handleFieldChange = (e, index, fromForm = false) => {
    if (e.target.name == 'amount') {
      if (fromForm) {
        setAmountToBeSplit(e.target.value);
      }
      setFormData(prev => {
        // Create a copy of the previous state array
        const updatedFormData = [...prev];
        // Update the specific element using the index
        updatedFormData[index].allocs[e.target.name] = e.target.value;
        updatedFormData[index][e.target.name] = e.target.value;
        return updatedFormData;
      });
    } else if (e.target.name === 'cheque_number') {
      let chequeNumberPattern = /^\d*$/;
      if (chequeNumberPattern.test(e.target.value.replace(/\s+/g, ''))) {
        setFormData(prev => {
          // Create a copy of the previous state array
          const updatedFormData = [...prev];
          // Update the specific element using the index
          updatedFormData[index][e.target.name] = e.target.value;
          return updatedFormData;
        });
      }
    } else if (e.target.type === 'checkbox') {
      const value = e.target.checked ? 1 : 0;
      setFormData(prev => {
        const updatedFormData = [...prev];
        updatedFormData[index] = {
          ...updatedFormData[index],
          is_pdc: value
        };
        return updatedFormData;
      });
    } else {
      setFormData(prev => {
        // Create a copy of the previous state array
        const updatedFormData = [...prev];
        // Update the specific element using the index
        updatedFormData[index][e.target.name] = e.target.value;
        return updatedFormData;
      });
    }
  };

  const handleChange = (e, action, singleFormData, index) => {
    if (action?.action == 'select-option' && action.name === 'bank_account') {
      setFormData(prev => {
        // Create a copy of the previous state array
        const updatedFormData = [...prev];
        updatedFormData[index]['deposit_to'] = e.value;
        updatedFormData[index]['deposit_to' + '_ref'] = e;
        return updatedFormData;
      });
      setFormData(prev => [...prev]);
    } else if (action?.action == 'clear' && action.name === 'bank_account') {
      setFormData(prev => {
        const updatedFormData = [...prev];
        updatedFormData[index] = {
          ...updatedFormData[index],
          deposit_to: '',
          deposit_to_ref: ''
        };
        return updatedFormData;
      });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSaving(true);
    const sumOfAmount = formData.reduce(
      (accumulator, current) => accumulator + parseFloat(current.amount),
      0
    );

    if (sumOfAmount > parseFloat(dueAmount)) {
      showToast(
        'Amount should be less than or equal to balance amount',
        'error'
      );
      setSaving(false);
    } else {
      const processedFormData = formData.map(item => {
        if (item.payment_mode !== 'cheque') {
          const newItem = { ...item };
          delete newItem.is_pdc;
          return newItem;
        }
        return item;
      });
      axios({
        method: 'post',
        url: 'finance/customer-receipt/bulk-store',
        data: { payments: processedFormData, stringify: false }
      })
        .then(response => {
          const responseData = response?.data;
          const responseDataFirstIndex = responseData?.data[0];
          if (responseData?.success === true) {
            fetchData(false);
            if (onPaymentSuccess) {
              const data = {
                ...responseDataFirstIndex,
                id: responseDataFirstIndex?.voucher_id
              };
              onPaymentSuccess(data);
            }
            setFormError({});
            setSaving(false);
            setFormData([
              {
                ...paymentChequeScreenFormKeys,
                customer_id: invoiceData.customer_id,
                cost_center_id: invoiceData.cost_center_id ?? '',
                allocs: {
                  customer_id: invoiceData.customer_id,
                  voucher_id: invoiceData.voucher_id,
                  amount: '',
                  trans_type: 'SI'
                }
              }
            ]);
            onHide();
          } else {
            showToast(
              'Something went wrong, please refresh the page and try again.',
              'error'
            );
          }
          setSaving(false);
        })
        .catch(error => {
          const errorData = error?.response?.data;
          showToast(
            errorData?.message ??
              error?.message ??
              'Something went wrong, please refresh the page and try again.',
            'error'
          );
          setFormError({ ...(errorData?.data?.errors ?? null) });
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    setFormData([
      {
        ...paymentChequeScreenFormKeys,
        trans_date: convertDateFormat(new Date(), 'YYYY-MM-DD'),
        amount: dueAmount,
        customer_id: invoiceData.customer_id,
        cost_center_id: invoiceData.cost_center_id ?? '',
        allocs: {
          customer_id: invoiceData.customer_id,
          voucher_id: invoiceData.voucher_id,
          amount: dueAmount,
          trans_type: 'SI'
        },
        deposit_to: defaultBankAccount?.bank_account_id ?? '',
        deposit_to_ref: {
          label: defaultBankAccount?.bank_name ?? '',
          value: defaultBankAccount?.bank_account_id ?? ''
        }
      }
    ]);
  }, [
    dueAmount,
    defaultBankAccount?.bank_account_id,
    defaultBankAccount?.bank_name,
    invoiceData.cost_center_id,
    invoiceData.customer_id,
    invoiceData.voucher_id,
    show
  ]);

  const handleCancelGenerateCheque = () => {
    setView(false);
    setPeriod(
      noOfCheques === 2
        ? 'halfYearly'
        : noOfCheques === 4
        ? 'quarterly'
        : noOfCheques === 12
        ? 'monthly'
        : 'custom'
    );
    setFormData(prev => {
      const updatedFormData = {
        ...prev[0],
        amount: parseFloat(amountToBeSplit),
        allocs: {
          ...prev[0].allocs,
          amount: parseFloat(amountToBeSplit)
        }
      };
      return [updatedFormData];
    });
  };

  const handleCancel = () => {
    if (view) {
      handleCancelGenerateCheque();
      return;
    }
    setFormData([]);
    onHide();
    setNoOfCheques(1);
    setFormError({});
    setPeriod('custom');
    setView(false);
  };

  const handleGenerateCheque = () => {
    const noOfChequesParsed = parseFloat(noOfCheques);

    if (noOfChequesParsed <= 0) {
      console.error('Number of cheques must be greater than zero.');
      return;
    }

    setView(true);

    setFormData(prev => {
      let updatedFormData = [...prev];

      const totalAmountToAllocate = parseFloat(updatedFormData[0]?.amount);
      if (isNaN(totalAmountToAllocate)) {
        console.error('Invalid amount provided.');
        return;
      }

      const amountPerCheque = totalAmountToAllocate / noOfChequesParsed;

      if (updatedFormData.length > noOfChequesParsed) {
        updatedFormData = updatedFormData.slice(0, noOfChequesParsed);
      } else if (updatedFormData.length < noOfChequesParsed) {
        const newChequesCount = noOfChequesParsed - updatedFormData.length;
        const newCheques = Array.from({ length: newChequesCount }, () => ({
          ...paymentChequeScreenFormKeys,
          amount: amountPerCheque,
          customer_id: invoiceData.customer_id,
          cost_center_id: invoiceData.cost_center_id ?? '',
          allocs: {
            customer_id: invoiceData.customer_id,
            voucher_id: invoiceData.voucher_id,
            amount: amountPerCheque,
            trans_type: 'SI'
          },
          cheque_bank: updatedFormData[0]?.cheque_bank || '',
          deposit_to: updatedFormData[0]?.deposit_to || '',
          deposit_to_ref: updatedFormData[0]?.deposit_to_ref || {},
          // memo: updatedFormData[0]?.memo || '',
          trans_date: updatedFormData[0]?.trans_date || '',
          trans_ref: updatedFormData[0]?.trans_ref || '',
          cheque_number: updatedFormData[0]?.cheque_number || '',
          cheque_date: updatedFormData[0]?.cheque_date || '',
          is_pdc: updatedFormData[0]?.is_pdc || 1
        }));
        updatedFormData = [...updatedFormData, ...newCheques];
      }

      // Get the initial cheque number and date from the first cheque
      const initialChequeNumber = updatedFormData[0]?.cheque_number || '';
      const initialChequeDate = updatedFormData[0]?.cheque_date || '';
      const parsedInitialDate = moment(initialChequeDate, 'YYYY-MM-DD'); // Adjusted for "YYYY-MM-DD" format

      updatedFormData = updatedFormData.map((item, index) => {
        // Increment cheque_number
        let chequeNumber = initialChequeNumber;
        if (index > 0 && initialChequeNumber) {
          const match = initialChequeNumber.match(/(\D*)(\d+)$/);
          if (match) {
            const prefix = match[1] || '';
            const number = parseInt(match[2], 10) + index; // Increment by the index
            chequeNumber = `${prefix}${number}`;
          }
        }

        // Increment cheque_date
        let chequeDate = initialChequeDate;
        if (index > 0 && parsedInitialDate.isValid()) {
          const dateClone = parsedInitialDate.clone(); // Clone the initial date to avoid mutation
          let incrementValue;

          if (period === 'custom') {
            incrementValue = 1; // Increment by one month for custom period
          } else {
            incrementValue = Math.ceil(12 / noOfChequesParsed); // Calculate increment value based on the number of cheques
          }

          chequeDate = dateClone
            .add(incrementValue * index, 'months')
            .format('YYYY-MM-DD');
        }

        return {
          ...item,
          amount: amountPerCheque,
          cheque_number: chequeNumber,
          cheque_date: chequeDate,
          allocs: {
            ...item.allocs,
            amount: amountPerCheque
          }
        };
      });

      return updatedFormData;
    });
  };

  const handlePeriod = period => {
    let noOfCheques;
    switch (period) {
      case 'custom':
        noOfCheques = 1;
        break;
      case 'monthly':
        noOfCheques = 12;
        break;
      case 'quarterly':
        noOfCheques = 4;
        break;
      case 'halfYearly':
        noOfCheques = 2;
        break;
      default:
        noOfCheques = '';
    }
    setNoOfCheques(noOfCheques);
    setPeriod(period);
  };

  const shouldDisableGenerateChequesButton = (formDataLength, noOfCheques) => {
    return (
      parseFloat(noOfCheques) <= 1 ||
      !parseFloat(noOfCheques) ||
      parseFloat(noOfCheques) === formDataLength
    );
  };

  const isMandatoryFilled = () => {
    if (
      formData?.[0]?.payment_mode === 'cheque' &&
      formData?.[0]?.allocs?.amount &&
      formData?.[0]?.cheque_number &&
      formData?.[0]?.cheque_date &&
      formData?.[0]?.trans_date &&
      formData?.[0]?.deposit_to_ref?.value &&
      formData?.[0]?.deposit_to_ref?.value &&
      formData?.[0]?.cheque_number
    ) {
      return true;
    }
  };

  return (
    <>
      <Modal
        show={show}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        style={{ zIndex: 9998 }}
        backdrop="static"
        className="modal-with-overlay"
      >
        <Card>
          <Card.Header className="fs-1 text-dark d-flex align-items-center border-bottom">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div>
                <MdOutlinePayments size={20} className={'me-2'} />
                {Translate('Payment for')}&nbsp;
                <span style={{ cursor: 'pointer' }}>
                  {invoiceData.reference}
                </span>
              </div>
              <AiOutlineClose
                size={22}
                className="text-danger cursor-pointer"
                onClick={handleCancel}
              />
            </div>
          </Card.Header>
          <Form onSubmit={handleSubmit}>
            <Card.Body className="pt-1">
              <Col md={8} sm={12} className={'m-0'}>
                <div className="p-2 ps-0 d-flex align-items-center">
                  {Translate('Total Balance Amount')}
                  &nbsp;: &nbsp;
                  <span className="fw-bold text-warning">
                    {dueAmount
                      ? `${baseCurrency} ` +
                        dueAmount.toLocaleString(navigator.language, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      : `${baseCurrency} 0.00`}
                  </span>
                </div>
              </Col>
              <div>
                {!Object.keys(formError).length == 0 ? (
                  <ErrorAlert
                    setFormError={setFormError}
                    formError={formError}
                  />
                ) : (
                  ''
                )}
              </div>
              <Form.Group as={Row} className="mt-2">
                <Col md={6} sm={12}>
                  <Form.Label>{Translate('Customer Name')}</Form.Label>
                  <Form.Control disabled value={person.name} />
                </Col>
                <Col md={6} sm={12}>
                  <Form.Label>{Translate('Reference')}</Form.Label>
                  <Form.Control disabled value={invoiceData.reference} />
                </Col>
              </Form.Group>
              <div className="mt-2">
                {!view ? (
                  <>
                    {formData.map((items, index) => {
                      const formDataLength = formData.length;
                      if (items.payment_mode === 'cheque') {
                        return (
                          <Card
                            className="mt-2 pb-3"
                            style={{ borderLeft: '1px solid green' }}
                            key={index}
                          >
                            <Card.Body className="pb-0">
                              <Form.Group as={Row}>
                                <Col md={3} sm={12}>
                                  <Form.Label className="m-0 require-data">
                                    {Translate('Amount Received')}(
                                    {baseCurrency})
                                  </Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text className="fs--1">
                                      {baseCurrency}
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="amount"
                                      id={`amount-${index}`}
                                      value={items.allocs.amount}
                                      onChange={e => {
                                        handleFieldChange(e, index, true);
                                      }}
                                    />
                                    {formError && formError.amount ? (
                                      <span className="ms-2">
                                        <FormErrorPopover
                                          id="amount"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={formError.amount}
                                        />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </InputGroup>
                                </Col>

                                <Col md={3} sm={12}>
                                  <Form.Label className="m-0">
                                    {Translate('Payment Mode')}
                                  </Form.Label>
                                  <Form.Select
                                    name="payment_mode"
                                    value={items.payment_mode}
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                    isInvalid={!!formError.payment_mode}
                                  >
                                    <option value="cheque">
                                      {Translate('Cheque')}
                                    </option>
                                    <option value="cash">
                                      {Translate('Cash')}
                                    </option>
                                    <option value="card">
                                      {Translate('Card')}
                                    </option>
                                    <option value="bank_transfer">
                                      {Translate('Bank Transfer')}
                                    </option>
                                  </Form.Select>
                                  {items?.payment_mode === 'cheque' && (
                                    <div className="d-flex align-items-center gap-3 mt-2">
                                      <Form.Label className="m-0">
                                        {Translate('Is Post-Dated Cheque?')}
                                      </Form.Label>
                                      <Form.Check
                                        type="checkbox"
                                        value={items?.is_pdc}
                                        checked={
                                          items?.is_pdc === 1 ? true : false
                                        }
                                        onChange={e =>
                                          handleFieldChange(e, index)
                                        }
                                      />
                                    </div>
                                  )}
                                </Col>
                                <Col md={3} sm={12}>
                                  <Form.Label className="m-0 require-data">
                                    {Translate('Cheque Number')}
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="cheque_number"
                                    value={items.cheque_number}
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                    min={0}
                                    onKeyDown={e =>
                                      ['e', 'E', '+', '-'].includes(e.key) &&
                                      e.preventDefault()
                                    }
                                  />
                                </Col>
                                <Col md={3} sm={12}>
                                  <Form.Label className="m-0 require-data">
                                    {Translate('Cheque Date')}
                                  </Form.Label>
                                  <AppDatePicker
                                    name="cheque_date"
                                    value={items.cheque_date}
                                    yearPlaceholder="yyyy"
                                    monthPlaceholder="mm"
                                    dayPlaceholder="dd"
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                    calendarClassName="position-relative"
                                  />
                                </Col>
                                <Col
                                  md={3}
                                  sm={12}
                                  className={
                                    formError.deposit_to ? 'ms-0 ps-0' : ''
                                  }
                                >
                                  <Form.Label className="m-0 mt-3 require-data">
                                    {Translate('Payment Date')}
                                  </Form.Label>
                                  <AppDatePicker
                                    name="trans_date"
                                    value={items.trans_date}
                                    yearPlaceholder="yyyy"
                                    monthPlaceholder="mm"
                                    dayPlaceholder="dd"
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                    showDefaultDate
                                    calendarClassName="position-relative"
                                  />
                                </Col>
                                <Col md={3} sm={12}>
                                  <Form.Label className="m-0 mt-3 require-data">
                                    {Translate('Deposit To')}
                                  </Form.Label>
                                  <SelectBankAccount
                                    value={items.deposit_to_ref}
                                    error={formError.deposit_to}
                                    handleFieldChange={(e, action) => {
                                      handleChange(e, action, items, index);
                                    }}
                                  />
                                </Col>
                                <Col md={3} sm={12}>
                                  <Form.Label className="m-0 mt-3">
                                    {Translate('Cheque Bank')}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="cheque_bank"
                                    value={items.cheque_bank}
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                  />
                                </Col>
                                <Col md={3} sm={12}>
                                  <Form.Label className="m-0 mt-3">
                                    {Translate('Transaction Ref')}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="trans_ref"
                                    value={items.trans_ref}
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                  />
                                </Col>
                                {items.payment_mode === 'cheque' &&
                                  index === 0 && (
                                    <Col md={3} sm={12}>
                                      <Form.Label className="m-0 mt-3">
                                        {Translate('Period')}
                                      </Form.Label>
                                      <Form.Select
                                        name="period"
                                        value={period}
                                        onChange={e => {
                                          handlePeriod(e.target.value);
                                        }}
                                        isInvalid={!!formError.period}
                                      >
                                        <option value="custom">
                                          {Translate('Custom')}
                                        </option>
                                        <option value="monthly">
                                          {Translate('Monthly')}
                                        </option>
                                        <option value="quarterly">
                                          {Translate('Quarterly')}
                                        </option>
                                        <option value="halfYearly">
                                          {Translate('Half Yearly')}
                                        </option>
                                      </Form.Select>
                                    </Col>
                                  )}
                                {items.payment_mode === 'cheque' &&
                                  index === 0 && (
                                    <Col md={3} sm={12}>
                                      <Form.Label className="m-0 mt-3">
                                        {Translate('No.Cheque')}
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        name="noOfCheques"
                                        value={noOfCheques}
                                        onChange={e => {
                                          let value = parseInt(
                                            e.target.value,
                                            10
                                          );
                                          if (value === 0) {
                                            showToast(
                                              'Number of Cheques cannot be zero',
                                              'warning'
                                            );
                                            value = 1;
                                          }
                                          setNoOfCheques(value);
                                        }}
                                        min={1}
                                        onKeyDown={e =>
                                          ['e', 'E', '+', '-', '.'].includes(
                                            e.key
                                          ) && e.preventDefault()
                                        }
                                      />
                                    </Col>
                                  )}
                                <Col md={12} sm={12}>
                                  <Form.Label className="m-0 mt-3">
                                    {Translate('Notes')}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    as="textarea"
                                    name="memo"
                                    row={2}
                                    value={items.memo}
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                  />
                                </Col>
                              </Form.Group>

                              <div className="w-100 d-flex align-items-center  m-0 p-0  justify-content-end pt-1">
                                {formDataLength - 1 === index && (
                                  <>
                                    {parseFloat(noOfCheques) <= 1 ||
                                    !parseFloat(noOfCheques) ? (
                                      <div className="mt-1 text-center pt-2 cursor-pointer">
                                        <FcPlus
                                          size={18}
                                          className="text-primary"
                                          onClick={AddNewPayment}
                                        />
                                      </div>
                                    ) : (
                                      <>
                                        {parseFloat(noOfCheques) ===
                                        formDataLength ? null : (
                                          <div className="mt-1 text-center pt-2">
                                            <Button
                                              variant="info"
                                              className="fs--1"
                                              size="sm"
                                              onClick={handleGenerateCheque}
                                              disabled={!isMandatoryFilled()}
                                            >
                                              Generate Cheques
                                            </Button>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                                {index !== 0 && (
                                  <div className="mt-1 text-center pt-2 ms-2 cursor-pointer">
                                    <FaTrash
                                      title="Remove column"
                                      className="text-danger cursor-pointer"
                                      onClick={() => {
                                        removeColumn(index);
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </Card.Body>
                          </Card>
                        );
                      } else if (items.payment_mode === 'bank_transfer') {
                        return (
                          <Card
                            className="mt-2 pb-3"
                            style={{ borderLeft: '1px solid green' }}
                            key={index}
                          >
                            <Card.Body className="pb-0">
                              <Form.Group as={Row}>
                                <Col md={4} sm={12}>
                                  <Form.Label className="m-0 require-data">
                                    {Translate('Amount Received')}(
                                    {baseCurrency})
                                  </Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text className="fs--1">
                                      {baseCurrency}
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="amount"
                                      value={items.allocs.amount}
                                      onChange={e => {
                                        handleFieldChange(e, index);
                                      }}
                                    />
                                    {formError && formError.amount ? (
                                      <span className="ms-2">
                                        <FormErrorPopover
                                          id="amount"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={formError.amount}
                                        />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </InputGroup>
                                </Col>

                                <Col md={4} sm={12}>
                                  <Form.Label className="m-0">
                                    {Translate('Payment Mode')}
                                  </Form.Label>
                                  <Form.Select
                                    name="payment_mode"
                                    value={items.payment_mode}
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                    isInvalid={!!formError.payment_mode}
                                  >
                                    <option value="cheque">
                                      {Translate('Cheque')}
                                    </option>
                                    <option value="cash">
                                      {Translate('Cash')}
                                    </option>
                                    <option value="card">
                                      {Translate('Card')}
                                    </option>
                                    <option value="bank_transfer">
                                      {Translate('Bank Transfer')}
                                    </option>
                                  </Form.Select>
                                  {items?.payment_mode === 'cheque' && (
                                    <div className="d-flex align-items-center gap-3 mt-2">
                                      <Form.Label className="m-0">
                                        {Translate('Is Post-Dated Cheque?')}
                                      </Form.Label>
                                      <Form.Check
                                        type="checkbox"
                                        value={items?.is_pdc}
                                        checked={
                                          items?.is_pdc === 1 ? true : false
                                        }
                                        onChange={e =>
                                          handleFieldChange(e, index)
                                        }
                                      />
                                    </div>
                                  )}
                                </Col>

                                <Col
                                  md={4}
                                  sm={12}
                                  className={
                                    formError.deposit_to ? 'ms-0 ps-0' : ''
                                  }
                                >
                                  <Form.Label className="m-0 require-data">
                                    {Translate('Payment Date')}
                                  </Form.Label>
                                  <AppDatePicker
                                    name="trans_date"
                                    value={items.trans_date}
                                    yearPlaceholder="yyyy"
                                    monthPlaceholder="mm"
                                    dayPlaceholder="dd"
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                    showDefaultDate
                                    calendarClassName="position-relative"
                                  />
                                </Col>
                                <Col md={3} sm={12}>
                                  <Form.Label className="m-0 mt-3 require-data">
                                    {Translate('Deposit To')}
                                  </Form.Label>
                                  <SelectBankAccount
                                    value={items.deposit_to_ref}
                                    error={formError.deposit_to}
                                    handleFieldChange={(e, action) => {
                                      handleChange(e, action, items, index);
                                    }}
                                  />
                                </Col>
                                <Col md={3} sm={12}>
                                  <Form.Label className="m-0 mt-3">
                                    {Translate('Transaction Ref')}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="trans_ref"
                                    value={items.trans_ref}
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                  />
                                </Col>
                                <Col md={3} sm={12}>
                                  <Form.Label className="m-0 mt-3">
                                    {Translate('Bank charges(If any)')}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="bank_charge"
                                    value={items.bank_charge}
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                  />
                                </Col>
                                <Col md={3} sm={12}>
                                  <Form.Label className="m-0 mt-3">
                                    {Translate('Notes')}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="memo"
                                    value={items.memo}
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                              <div className="w-100 d-flex align-items-center  m-0 p-0  justify-content-end pt-1">
                                {formDataLength - 1 === index && (
                                  <div className="mt-1 text-center pt-2 me-2 cursor-pointer">
                                    <FcPlus
                                      size={18}
                                      className="text-primary"
                                      onClick={AddNewPayment}
                                    />
                                  </div>
                                )}
                                {index !== 0 && (
                                  <div className="mt-1 text-center pt-2 cursor-pointer">
                                    <FaTrash
                                      title="Remove column"
                                      className="text-danger cursor-pointer"
                                      onClick={() => {
                                        removeColumn(index);
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </Card.Body>
                          </Card>
                        );
                      } else {
                        return (
                          <Card
                            className="mt-2 pb-3"
                            style={{ borderLeft: '1px solid green' }}
                            key={index}
                          >
                            <Card.Body className="pb-0">
                              <Form.Group as={Row}>
                                <Col md={4} sm={12}>
                                  <Form.Label className="m-0 require-data">
                                    {Translate('Amount Received')}(
                                    {baseCurrency})
                                  </Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text className="fs--1">
                                      {baseCurrency}
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="amount"
                                      value={items.allocs.amount}
                                      onChange={e => {
                                        handleFieldChange(e, index);
                                      }}
                                    />
                                    {formError && formError.amount ? (
                                      <span className="ms-2">
                                        <FormErrorPopover
                                          id="amount"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={formError.amount}
                                        />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </InputGroup>
                                </Col>

                                <Col md={4} sm={12}>
                                  <Form.Label className="m-0">
                                    {Translate('Payment Mode')}
                                  </Form.Label>
                                  <Form.Select
                                    name="payment_mode"
                                    value={items.payment_mode}
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                    isInvalid={!!formError.payment_mode}
                                  >
                                    <option value="cheque">
                                      {Translate('Cheque')}
                                    </option>
                                    <option value="cash">
                                      {Translate('Cash')}
                                    </option>
                                    <option value="card">
                                      {Translate('Card')}
                                    </option>
                                    <option value="bank_transfer">
                                      {Translate('Bank Transfer')}
                                    </option>
                                  </Form.Select>
                                  {items?.payment_mode === 'cheque' && (
                                    <div className="d-flex align-items-center gap-3 mt-2">
                                      <Form.Label className="m-0">
                                        {Translate('Is Post-Dated Cheque?')}
                                      </Form.Label>
                                      <Form.Check
                                        type="checkbox"
                                        value={items?.is_pdc}
                                        checked={
                                          items?.is_pdc === 1 ? true : false
                                        }
                                        onChange={e =>
                                          handleFieldChange(e, index)
                                        }
                                      />
                                    </div>
                                  )}
                                </Col>

                                <Col
                                  md={4}
                                  sm={12}
                                  className={
                                    formError.deposit_to ? 'ms-0 ps-0' : ''
                                  }
                                >
                                  <Form.Label className="m-0 require-data">
                                    {Translate('Payment Date')}
                                  </Form.Label>
                                  <AppDatePicker
                                    name="trans_date"
                                    value={items.trans_date}
                                    yearPlaceholder="yyyy"
                                    monthPlaceholder="mm"
                                    dayPlaceholder="dd"
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                    showDefaultDate
                                    calendarClassName="position-relative"
                                  />
                                </Col>
                                <Col md={4} sm={12}>
                                  <Form.Label className="m-0 require-data mt-3">
                                    {Translate('Deposit To')}
                                  </Form.Label>
                                  <SelectBankAccount
                                    value={items.deposit_to_ref}
                                    error={formError.deposit_to}
                                    handleFieldChange={(e, action) => {
                                      handleChange(e, action, items, index);
                                    }}
                                  />
                                </Col>
                                <Col md={4} sm={12}>
                                  <Form.Label className="m-0 mt-3">
                                    {Translate('Transaction Ref')}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="trans_ref"
                                    value={items.trans_ref}
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                  />
                                </Col>
                                <Col md={4} sm={12}>
                                  <Form.Label className="m-0 mt-3">
                                    {Translate('Notes')}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="memo"
                                    value={items.memo}
                                    onChange={e => {
                                      handleFieldChange(e, index);
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                              <div className="w-100 d-flex align-items-center  m-0 p-0  justify-content-end pt-1">
                                {formDataLength - 1 === index && (
                                  <div className="mt-1 text-center pt-2 me-2 cursor-pointer">
                                    <FcPlus
                                      size={18}
                                      className="text-primary"
                                      onClick={AddNewPayment}
                                    />
                                  </div>
                                )}
                                {index !== 0 && (
                                  <div className="mt-1 text-center pt-2 cursor-pointer">
                                    <FaTrash
                                      title="Remove column"
                                      className="text-danger cursor-pointer"
                                      onClick={() => {
                                        removeColumn(index);
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </Card.Body>
                          </Card>
                        );
                      }
                    })}
                  </>
                ) : (
                  <SimpleBar
                    style={{
                      maxHeight: '600px',
                      overflowY: 'auto'
                    }}
                  >
                    <PaymentChequeTable
                      data={formData}
                      handleFieldChange={handleFieldChange}
                      formError={formError}
                      handleChange={handleChange}
                    />
                  </SimpleBar>
                )}
              </div>
            </Card.Body>

            <Card.Footer className="d-flex gap-2 justify-content-end border-top">
              <Button
                variant="danger"
                size="sm"
                disabled={saving}
                onClick={handleCancel}
              >
                {Translate('Cancel')}
              </Button>
              <Button
                variant="success"
                size="sm"
                type={'submit'}
                disabled={
                  saving ||
                  !shouldDisableGenerateChequesButton(
                    formData.length,
                    noOfCheques
                  )
                }
              >
                {!saving
                  ? Translate('Record Payment')
                  : Translate('Saving') + '...'}
              </Button>
            </Card.Footer>
          </Form>
        </Card>
      </Modal>
    </>
  );
}

PaymentChequeScreen.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  invoiceData: PropTypes.any,
  fetchData: PropTypes.func,
  person: PropTypes.object,
  dueAmount: PropTypes.any,
  onPaymentSuccess: PropTypes.any
};

export default PaymentChequeScreen;
